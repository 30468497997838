<template>
    <footer class="footer">
        <div>
            <p>© 2022, Информационная система LikeBG: финансовый маркетплейс. Все права на визуально-графические
                изображения, информацию и аналитические материалы, размещенные на сайте принадлежат правообладателю и
                охраняются законодательством Российской Федерации.</p>

            <p>Программа для ЭВМ "Информационная система LikeBG: финансовый маркетплейс" предоставлена:
                Общество с ограниченной ответственностью «Лайк АйТи»<br />
                Юридический адрес: 606522, Нижегородская область, Городецкий р-н, г. Заволжье, пр-кт Дзержинского, д. 12,
                кв. 42<br />

                Фактический адрес: 606522, Нижегородская область, г. Заволжье, пр-кт Дзержинского, д. 12, кв. 42
                ИНН: 5248046426; КПП: 524801001; ОГРН: 1235200034893<br />
                Электронная почта: ceo@like-it.ru. Номер телефона: +79101298009<br />
                ОКВЭД: 62.01 Разработка компьютерного программного обеспечения<br />
                Код вида ИТ-деятельности (согласно Приказа Минцифры России № 449): 2.01
            </p>
            <p>ООО «План Б» является Оператором Системы. ООО «Лайк АйТи», ООО «План Б» не оказывают финансовые (банковские)
                услуги. Все финансовые (банковские) услуги в рамках Информационной системы LikeBG оказывают Банки-Партнёры.
            </p>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style lang="scss">
.footer {
    padding: 80px 0;

    p {
        color: #999;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }


}

@media(max-width: 768px) {
    .footer {
        padding: 60px 0;

        p {
            font-size: 14px;
            line-height: 18px;
        }
    }
}
</style>